.editor__values__textEditor {
  padding: 10px 0;
}
.editor__values__textEditor textarea {
  width: 100%;
  min-height: 250px;
  padding: 1rem;
  outline-color: var(--main-color);
  border: 1px solid var(--main-color);
  margin-top: 10px;
  resize: none;
  line-height: 1.6;
  color: var(--text-color-light);
  border-radius: 4px;
}
.editor__values__textEditor label {
  font-size: 0.85rem;
}
.inputCard__body .richTextEditor {
  padding: 0;
  margin: 0;
}
