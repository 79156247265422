.colorInput {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.colorInput__title {
  font-size: 0.85rem;
}
.colorInput__input {
  -webkit-appearance: none;
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  background: none;
}
.colorInput__input:hover {
  cursor: pointer;
}
.colorInput__input::-webkit-color-swatch {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  box-sizing: border-box;
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
}
