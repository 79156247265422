.blah {
  color: white;
}
.editor__styles.width0 {
  width: 0;
}
.editor__styles {
  width: 20%;
}
.editor__styles .closePanel {
  right: calc(-1 * var(--width));
}

.editor__styles .closePanel img {
  transform: rotate(180deg);
}
.editor__styles.width0 .closePanel img {
  transform: rotate(0deg);
}
@media screen and (max-width: 768px) {
  .editor__styles {
    width: 100% !important;
  }
}
.editor__styles .overflow-wrap .panel-content-wrapper.respect-overflow > div {
  margin: 15px 0 !important;
}
