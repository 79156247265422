.navbarItems {
  list-style: none;
  display: flex;
  flex-direction: row;
}

.navbarItems a:hover {
  color: var(--main-color);
}
.header__navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar__hamburger {
  display: none;
  outline: none;
}

@media screen and (max-width: 720px) {
  .navbar__hamburger {
    display: flex;
    cursor: pointer;
    width: 50%;
    align-items: center;
    height: 100px;
    justify-content: flex-end;
  }
  .navbar__hamburger img {
    max-width: 50px;
  }

  /* hiding menu items on mobile */
  .header__navbar {
    padding: 0 0 1rem 0;
    margin-left: 5px;
    display: none;
    width: 100%;
    background-color: white;
  }

  .navbarItems {
    flex-direction: column;
    width: 100%;
  }
}

/* animation */

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.animated {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
