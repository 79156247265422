.editorNumberInput {
  --input-color: rgb(85, 85, 85);
  --input-border: var(--silver);
  --input-background: #fff;
  --input-placeholder: #cbd1dc;
  --input-border-focus: #0d5a137e;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: var(--secondary-color);
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678efe;
  display: flex;
  flex-direction: column;
}
.editorNumberInput--multiInput .editorNumberInput__input--wrapper {
  position: relative;
}
.editorNumberInput__label {
  font-size: 0.85rem;
  padding: 0.5rem 0;
}
.editorNumberInput__input--wrapper {
  display: flex;
  padding: 0.5rem 0;
}
.editorNumberInput__input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 1px solid var(--input-border);
  border-top-right-radius: 0;
  border-right: none;
  border-bottom-right-radius: 0;
  background: var(--input-background);
  transition: border 0.3s ease;
}
.editorNumberInput__input::-moz-placeholder {
  color: var(--input-placeholder);
}
.editorNumberInput__input:-ms-input-placeholder {
  color: var(--input-placeholder);
}
.editorNumberInput__input::placeholder {
  color: var(--input-placeholder);
}
.editorNumberInput__input:focus {
  outline: none;
  font-weight: bold;
}

.editorNumberInputButton {
  text-align: center;
  padding: 0 8px;
  font-size: 10px;
  width: 50px;
  display: grid;
  place-items: center;
  color: var(--group-color);
  background: var(--group-background);
  border-right: 1px solid var(--group-border);
}
#editorNumberInputButtonActive {
  color: var(--white-color);
  background-color: var(--main-color);
}
.editorNumberInputButton:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.editorNumberInput__input--multiple {
  border-radius: 0;
  padding: 8px 5px;
  text-align: center;
  position: relative;
}
.multiInputWrapper {
  position: relative;
}
.multiInputWrapper p {
  position: absolute;
  margin-top: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  color: rgb(125 125 125 / 82%);
}
.multiInputLinkButton {
  position: absolute;
  top: -30px;
  right: 0px;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  display: grid;
  place-content: center;
}
.multiInputLinkButton img {
  max-width: 15px;
}
#multiInputLinkButtonActive {
  background-color: var(--main-color);
  /* background-color: var(--silver); */
}
#multiInputLinkButtonActive img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(252deg)
    brightness(106%) contrast(100%);
}
