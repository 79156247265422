.navbarItem {
  padding: 10px 15px;
  /* align-self: center; */
}
.navbarItem__link {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 300;
  font-size: 14px;
}
