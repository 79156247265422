.scopeStyles {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0.8rem;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}
.scopeStyles__title {
  font-size: 0.85rem;
}
.scopeStyles__body {
  display: flex;
}

.scopeStyles__body > * {
  margin: 0 5px;
}
