.quill {
  margin-top: 0.8rem;
}
.ql-editor {
  min-height: 150px;
  background-color: #f6f7f9;
  font-family: 0.9rem;
  line-break: anywhere;
}

.ql-toolbar.ql-snow {
  border: none;
  background-color: #f6f7f9;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.ql-container.ql-snow {
  border: none;
}

.richTextEditor {
  padding: 15px;
}
