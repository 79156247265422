.affipal__button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  outline: none;
  transition: all 100ms ease-in-out;
  background: var(--button-color);
  color: var(--white-color);
  border-radius: 5px;
  overflow: hidden;
  min-width: 150px;
  user-select: none;
}
.affipal__button:hover {
  opacity: 0.9;
}

.affipal__button--white {
  --button-color: white;
  --white-color: var(--main-color);
  border: 1px solid rgba(212, 212, 212, 0.726);
}

.affipal__button:active {
  transform: scale(0.95);
}

.affipal__button img {
  width: 15px;
}

@media screen and (max-width: 1400px) {
  .affipal__button {
    margin-right: 10px;
    padding: 1rem;
  }
}
