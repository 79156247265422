.inputCard {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  transform: all 0.3s ease;
  width: 100%;
}

.inputCard-inside {
  border: 0 !important;
}
.inputCard-inside .inputCard__body,
.inputCard-inside .inputCard__header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 0px;
  border: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.inputCard-inside .inputCard__header button {
  margin-right: 8px;
}
.inputCard__header {
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 75px;
}
.inputCard__header > div {
  font-size: 0.85rem;
}
.inputCard__header button {
  padding: 10px;
  border-radius: 50px;
  display: grid;
  place-items: center;
}
.inputCard__header button svg {
  width: 25px;
}
#inputCard__body-hidden,
.inputCard__body-hidden {
  max-height: 0px;
  padding: 0;
  overflow: hidden;
  transition-delay: -150ms;
  visibility: hidden;
  opacity: 0;
}

.inputCard__body {
  max-height: 100rem;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition-duration: 0.2s;
  flex-direction: column;
  padding: 0.8rem;
  border-top: 1px solid var(--secondary-color);
}
.inputCard__body > div {
  margin: 0.8rem 0;
}
.inputCard__body * {
  font-size: 0.8rem;
}
/* animations */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

/* animations */
